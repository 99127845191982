$DARK_BLUE: #463DED; // Links
$BLUE: #6396BA; 
$LIGHT_BLUE: #63B3BA;
$GREEN: #63BA88;
$ORANGE: #F27F0C; // Offers
$LIGHT_GREY: #FBFBFD;
$GREY: #EEEEEE; // Input
$DARK_GREY: #8C8C8C;
$WHITE: #FFFFFF;
$BLACK: #151516;
$LIGHTER_BLACK: #1D1D1F
