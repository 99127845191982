@import "../../Styles.scss";

.footer {
    background-color: $LIGHT_GREY;
    padding: $SPACING_L;
    
    a {
        color: $BLACK;
    }
    &-container {
        color: $BLACK;
        margin-bottom: $SPACING_L;
        &:last-of-type {
            margin: 0;
        }
    }
    &-disclaimers {
        max-width: calc($SCREEN_SIZE_L - $SPACING_M * 2);
        margin: 0 auto $SPACING_M;
        padding: $SPACING_M;
        border-top: 2px solid $GREY;
        border-bottom: 2px solid $GREY;
        
        &-list {
            list-style-type: none;
            &-item {
                font-size: $TEXT_S;
                padding: $SPACING_XXS 0;
                color: $DARK_GREY;
            }
        }
    }
    &-content {
        display: grid;
        grid-template-columns: 1fr;
        max-width: $SCREEN_SIZE_L;
        margin: 0 auto;
        @media (min-width: $SCREEN_SIZE_S) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    &-socials {
        &-icons {
            display: flex;
            margin-top: $SPACING_XS;
            gap: $SPACING_XS;

            & > a {
                font-size: $TEXT_XXL;
            }
        }
    }
    &-menu {
        &-list {
            display: flex;
            gap: $SPACING_XL;
            &-item {
                list-style-type: none;
                margin: $SPACING_XS 0;
            }
        }
    }
    &-copyright {
        max-width: $SCREEN_SIZE_L;
        border-top: 1px solid $DARK_GREY;
        margin: $SPACING_M auto 0;
        padding: $SPACING_S 0 0;
        color: $LIGHTER_BLACK;
        & p {
            font-size: $TEXT_S;
        }
    }
}