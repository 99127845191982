@import "./styles/Styles.scss";

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: $TEXT_4XL;
  @media (min-width: $SCREEN_SIZE_S) {
    font-size: $TEXT_5XL;
  }
}
h2 {
  font-size: $TEXT_XXL;
  font-weight: $MEDIUM;
}
p {
  font-size: $TEXT_L;
  font-weight: $THIN;
}
a {
  font-size: $TEXT_XL;
  text-decoration: none;
  color: $BLACK;
}

.text-dark-blue {
  color: $DARK_BLUE;
}
.text-blue {
  color: $BLUE;
}
.text-light-blue {
  color: $LIGHT_BLUE;
}
.text-green {
  color: $GREEN;
}
.text-orange {
  color: $ORANGE;
}
.text-black {
  color: $BLACK;
}
.text-light-grey {
  color: $LIGHT_GREY;
}
.text-grey {
  color: $GREY;
}
.text-white {
  color: $WHITE;
}
.bg-black {
  background-color: $BLACK;
}
.bg-light-grey {
  background-color: $LIGHT_GREY;
}
