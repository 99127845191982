// Screen sizes
$SCREEN_SIZE_XS: 400px; // Telefoon
$SCREEN_SIZE_S: 768px; // Kleine tables
$SCREEN_SIZE_M: 992px; // Grote tablet/kleine computer
$SCREEN_SIZE_L: 1280px; // Maximale schermgrootte
$SCREEN_SIZE_XL: 1400px; // 4K monitor+

// Font sizes
$TEXT_XXS: 0.5rem;
$TEXT_XS: 0.625rem;
$TEXT_S: 0.8125rem; // Tekst
$TEXT_M: 0.9375rem;
$TEXT_L: 1rem; // Subtitle
$TEXT_XL: 1.25rem;
$TEXT_XXL: 1.5rem; // Title
$TEXT_3XL: 1.875rem;
$TEXT_4XL: 2.125rem;
$TEXT_5XL: 2.7rem;
$TEXT_6XL: 3.5rem; // Animaties

// Border Radius Sizes
$BORDER_S: 0.375rem; // Ronde kantjes
$BORDER_M: 0.625rem;
$BORDER_L: 1rem;
$BORDER_XL: 3rem; // Volledig rond