@import "../../Styles.scss";

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: $WHITE;
    width: 100vw;

    &-content {
        max-width: $SCREEN_SIZE_L;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $SPACING_M;

        @media (min-width: $SCREEN_SIZE_S) {
            flex-direction: row;
            align-items: center;
            margin: auto;
            padding: $SPACING_XS $SPACING_L;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        font-size: $TEXT_XXL;
        font-weight: $REGULAR;
        white-space: nowrap;

        & img {
            max-height: 45px;
        }
    }
    &-text {
        font-size: $TEXT_L;
        font-weight: $REGULAR;
    }
    &-link {
        font-size: $TEXT_L;
        font-weight: $REGULAR;
    }
    &-socials {
        display: block;
        font-size: $TEXT_XXL;
        height: 24px;
        width: 24px;
    }
    &-icon {
        font-size: $TEXT_XXL;
        height: 25px;
        width: 25px;

        @media (min-width: $SCREEN_SIZE_S) {
            display: none;
        }
    }

    &-section {
        display: none;

        @media (min-width: $SCREEN_SIZE_S) {
            display: flex;
            align-items: center;
            gap: $SPACING_L;
            justify-content: flex-end;
            margin: $SPACING_S 0;
            padding-left: $SPACING_L;
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: $LIGHT_GREY;
    z-index: 9999;
    transition: 0.2s;
    transform: translateX(-100%);

    &--active {
        transform: translateX(0%);
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $SPACING_M $SPACING_M 0;

        &-close {
            background-color: $GREY;
            padding: $SPACING_XS $SPACING_XS calc($SPACING_XS - 5px) $SPACING_XS;
            border-radius: $BORDER_XL;
            font-size: $TEXT_XXL;
            cursor: pointer;
        }
        &-logo img {
            max-height: 45px;
        }
    }

    &-list {
        margin: $SPACING_M 0 $SPACING_M;
        padding: 0 $SPACING_M;
        list-style-type: none;

        & li {
            border-top: 0.5px solid $DARK_GREY;
            color: $LIGHTER_BLACK;

            & a {
                display: inline-block;
                padding: $SPACING_S 0;
                width: 100%;
            }

            &:first-of-type {
                border-top: none;
            }
        }
    }

    &-footer {
        display: flex;
        gap: $SPACING_M;
        align-items: center;
        border-top: 0.5px solid $DARK_GREY;
        padding: $SPACING_M;
        margin-top: auto;

        &-memoji {
            height: 75px;
        }

        &-socials {
            display: flex;
            margin-top: $SPACING_XS;
            gap: $SPACING_XS;

            & a {
                font-size: $TEXT_XXL;
            }
        }
    }
}
