// Input labels
$SPACING_XXS: 0.225rem;
// Kleine ruimte tussen items -> socials
$SPACING_XS: 0.5rem;
// Button padding
$SPACING_S: 0.825rem;
// Zijkanten mobiel padding
// Tussen items -> foto's of onderdelen
// Tussen tekst en titel
$SPACING_M: 1rem;
// Zijkanten desktop
// Tussen butten en tekst
$SPACING_L: 1.875rem; 
// Tussen 2 containers padding
$SPACING_XL: 3rem;
// Grote ruimte tussen 2 containers
$SPACING_XXL: 5rem;


// $SPACING_3XS: 0.125rem;
// $SPACING_XXS: 0.375rem;
// $SPACING_XS: 0.5rem;
// $SPACING_S: 0.625rem;
// $SPACING_XL: 2rem;
// $SPACING_XXL: 2.5rem;
// $SPACING_4XL: 4rem;
// $SPACING_5XL: 7.5rem;