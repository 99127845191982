@import "../../Styles.scss";

.contact-link {
    width: 100%;
    max-width: $SCREEN_SIZE_L;
    margin: 0 auto;

    &-contact {
        width: 100%;
        border-radius: $BORDER_M;
        background-color: $LIGHT_GREY;
        padding: $SPACING_L 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &-text {
        font-weight: $MEDIUM;
        color: $LIGHT_BLUE;
        font-size: $TEXT_XL;
    }
    &-button {
        margin-top: $SPACING_S;
    }
}
