@import "../Styles.scss";

.home {
  &-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: $SCREEN_SIZE_L;
    margin: 0 auto;
    padding: $SPACING_XL $SPACING_M;
    @media (min-width: $SCREEN_SIZE_M) {
      padding: $SPACING_XL $SPACING_L;
    }

    &--banner {
      overflow: hidden;
      max-width: $SCREEN_SIZE_L;
      margin: 0 auto;
      padding: 0 $SPACING_M $SPACING_XL $SPACING_M;
      @media (min-width: $SCREEN_SIZE_M) {
        padding: 0 $SPACING_L $SPACING_XXL $SPACING_L;
      }
    }
  }
  &-display {
    &--mobile {
      display: block;

      @media (min-width: $SCREEN_SIZE_S) {
        display: none;
      }
    }
  }
  &-title {
    position: relative;
    z-index: 1;
  }
  &-subtitle {
    position: relative;
    z-index: 1;
    &--thin {
      font-size: $TEXT_XL;
      font-weight: $THIN;
    }
  }
  &-text {
    margin-top: $SPACING_M;
    max-width: 700px;
    &--regular {
      font-size: $TEXT_XL;
      font-weight: $REGULAR;
    }
  }
  &-link--underline {
    text-decoration: underline;
  }
  &-underline {
    @keyframes underline-grow {
      0% {
        width: 0;
      }
      100% {
        width: 700px;
      }
    }
    &-blue {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $TEXT_XS;
        background-color: $LIGHT_BLUE;
        z-index: -1;

        @media (min-width: $SCREEN_SIZE_S) {
          height: $TEXT_S;
        }
      }
    }
    &-green {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $TEXT_XS;
        background-color: $GREEN;
        z-index: -1;

        @media (min-width: $SCREEN_SIZE_S) {
          height: $TEXT_S;
        }
      }
      &--low {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: $TEXT_XXS;
          background-color: $GREEN;
          z-index: -1;
        }
      }
      &--none {
        position: relative;
        transition: 0.8s;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: $TEXT_XS;
          background-color: $GREEN;
          z-index: -1;
          transition: 0.8s;

          @media (min-width: $SCREEN_SIZE_S) {
            height: $TEXT_XS;
          }
        }
      }
      &--long {
        position: relative;
        transition: 0.8s;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 105%;
          height: $TEXT_XS;
          background-color: $GREEN;
          z-index: -1;
          transition: 0.8s;

          @media (min-width: $SCREEN_SIZE_S) {
            height: $TEXT_S;
          }
          @media (min-width: $SCREEN_SIZE_M) {
            width: 500px;
          }
        }
      }
    }
  }
  &-button {
    align-self: flex-start;
    margin-top: $SPACING_M;
  }
  &-text-whitespace {
    display: inline-block;
    transition: 0.8s;
    &--active {
      margin-right: 0.5rem;
      @media (min-width: $SCREEN_SIZE_M) {
        margin-right: 1rem;
      }
    }
  }

  &-banner {
    display: flex;
    flex-direction: column;
    gap: $SPACING_XL;

    @media (min-width: $SCREEN_SIZE_S) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $SPACING_L;
    }

    &-image {
      justify-self: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: start;
      height: 275px;
      width: 250px;
      transform: translateX(50px);

      @keyframes blob {
        0% {
          border-radius: 50% 50% 37% 63% / 58% 30% 70% 42%;
        }
        25% {
          border-radius: 50% 50% 37% 63% / 50% 51% 49% 50%;
        }
        50% {
          border-radius: 54% 46% 55% 45% / 38% 51% 49% 62%;
        }
        75% {
          border-radius: 64% 36% 37% 63% / 52% 35% 65% 48%;
        }
        100% {
          border-radius: 50% 50% 37% 63% / 58% 30% 70% 42%;
        }
      }

      & * {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50% 50% 37% 63% / 58% 30% 70% 42%;
      }

      & div {
        background: linear-gradient(
          90deg,
          rgba(99, 150, 186, 1) 0%,
          rgba(99, 175, 186, 1) 100%
        );
        animation: blob 10s infinite linear;
      }

      & img {
        transform: translate(-50px, 40px);
      }

      @media (min-width: $SCREEN_SIZE_S) {
        order: 2;
        height: 375px;
        width: 350px;
      }
    }
    &-info {
      @media (min-width: $SCREEN_SIZE_S) {
        margin-top: $SPACING_L;
      }

      &-container {
        margin: $SPACING_L 0 $SPACING_S 0;
      }
    }
    &-socials {
      margin: $SPACING_XS 0;
      & > * {
        transition: 0.2s;
        font-size: $TEXT_3XL;
        &:nth-child(2) {
          margin-left: $SPACING_XS;
        }
        &:hover {
          color: $DARK_BLUE;
        }
      }
    }
    &-about-link {
      text-decoration: underline;
    }
    &-arrow {
      border-radius: $BORDER_XL;
      padding: 0.6rem;
      float: left;
      margin-top: $SPACING_XXS;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: $LIGHT_BLUE;
      color: $WHITE;

      & > * {
        font-size: $TEXT_3XL;
      }
    }
  }

  &-grey-background {
    background-color: $LIGHT_GREY;
    position: relative;
    left: 0;
    width: 100%;
  }

  &-mogelijkheden {
    display: flex;
    flex-direction: column;

    &-content {
      margin-top: $SPACING_L;
      display: grid;
      grid-template-columns: 1fr;
      gap: $SPACING_L;
      @media (min-width: $SCREEN_SIZE_S) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &-item {
      text-align: center;
      margin: $SPACING_M 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > img {
        width: 100%;
        max-width: 500px;
        margin: $SPACING_M 0;
      }
    }
    &-animaties-button {
      margin-top: $SPACING_L;
      align-self: flex-end;
      display: flex;
      align-items: center;
      font-size: $TEXT_XL;
      cursor: pointer;

      & > p {
        font-size: $TEXT_XL;
        margin-right: $SPACING_S;
      }
    }
  }
  &-onderhouden {
    @media (min-width: $SCREEN_SIZE_S) {
      flex-direction: row;
      align-items: center;
      gap: $SPACING_XL;
    }
    &-image {
      @media (min-width: $SCREEN_SIZE_S) {
        width: 50%;
      }
      & img {
        width: 100%;
        max-width: 500px;
        margin-bottom: $SPACING_M;
      }
    }
    &-content {
      justify-self: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
  &-seo {
    @media (min-width: $SCREEN_SIZE_S) {
      flex-direction: row;
      align-items: center;
      gap: $SPACING_XL;
    }
    &-image {
      @media (min-width: $SCREEN_SIZE_S) {
        width: 50%;
        order: 2;
      }
      & img {
        width: 100%;
        max-width: 500px;
        margin-bottom: $SPACING_M;
      }
    }
    &-content {
      justify-self: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
  &-statestieken {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $SPACING_L;
    @media (min-width: $SCREEN_SIZE_S) {
      grid-template-columns: repeat(3, 1fr);
    }

    &-item {
      text-align: center;
    }
  }

  &-portfolio {
    &-title {
      padding: $SPACING_L $SPACING_M $SPACING_XS;
      max-width: $SCREEN_SIZE_L;
      margin: 0 auto;
    }
    &-subtitle {
      padding: 0 $SPACING_M;
      font-weight: 300;
      font-size: $TEXT_XL;
      max-width: $SCREEN_SIZE_L;
      margin: 0 auto;
    }
    &-uitgelicht {
      position: relative;
      height: 100vh;
      width: 100vw;
      background-size: cover;
      display: flex;
      &-overlay {
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        background-color: $BLACK;
        opacity: 0.65;
      }
      &-content {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        z-index: 1;
        width: 100%;
        color: $WHITE;
      }
      &-title {
        display: flex;
        align-items: flex-end;
        gap: $SPACING_XS;
      }
      &-subtitle {
        color: $ORANGE;
        font-size: $TEXT_XL;
      }
    }
    &-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: $SPACING_XXL;
      list-style-type: none;

      @media (min-width: $SCREEN_SIZE_S) {
        grid-template-columns: repeat(2, 1fr);
      }

      &-item {
        position: relative;
        width: 100%;
        cursor: pointer;

        &-background {
          position: relative;
          width: 100%;
          padding-bottom: 55%;
          border-radius: $BORDER_M;
        }

        &:nth-child(1) &-background {
          background: linear-gradient(
            45deg,
            rgba(55, 35, 24, 1) 30%,
            rgba(130, 90, 62, 1) 100%
          );
        }
        &:nth-child(2) &-background {
          background: linear-gradient(45deg, #404040 10%, #4058f6 100%);
        }
        &:nth-child(4) &-background {
          background: linear-gradient(45deg, #919191 0%, #f3f3f3 100%);
        }
        &:nth-child(3) &-background {
          background: linear-gradient(45deg, #4e46e5 10%, #60c8b8 80%);
        }

        &-image {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          border-top-left-radius: $BORDER_M;
          border-top-right-radius: $BORDER_M;
          object-fit: cover;
          object-position: top;
          transform: scale(0.9);
          transform-origin: bottom;
          transition: 0.3s;
          box-shadow: 0 0 30px 0 rgb(0 0 0 / 12%);
        }

        &:hover &-image {
          transform: scale(1);
          border-radius: $BORDER_M;
        }

        &-title {
          margin-top: $SPACING_XS;
          text-align: center;
          transition: 0.3s;

          @media (hover: hover) {
            opacity: 0;
          }
        }

        &:hover &-title {
          opacity: 1;
        }
      }
    }
  }
  &-aanbieding {
    display: flex;
    flex-direction: column;
    background-color: $LIGHTER_BLACK;
    padding: $SPACING_L $SPACING_M;
    border-radius: $BORDER_M;
    @media (min-width: $SCREEN_SIZE_S) {
      padding: $SPACING_L;
    }
    &-buttons {
      display: flex;
      gap: $SPACING_L;
      align-items: center;
      margin-top: $SPACING_M;
    }
    &-link {
      text-decoration: underline;
      font-size: $TEXT_M;
    }
  }
  &-recensies {
    & ul {
      display: grid;
      gap: $SPACING_M;
      margin-top: $SPACING_L;
      @media (min-width: $SCREEN_SIZE_M) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &-list {
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $SPACING_S;
        text-align: center;
        background-color: $LIGHT_GREY;
        padding: $SPACING_S;
        border-radius: $BORDER_M;

        &-persoon {
          display: flex;
          align-items: center;
          text-align: start;
          gap: $SPACING_S;
          margin-top: auto;
        }
        &-image {
          width: 100%;
          max-width: 70px;
          max-height: 70px;
          object-fit: cover;
          border-radius: $BORDER_XL;
        }
        &-content {
          display: flex;
          flex-direction: column;
          & > p {
            color: $LIGHT_GREY;
          }
        }
      }
    }
  }
  &-faq {
    margin: 0 auto;
    text-align: center;
    &-list {
      display: grid;
      width: 100%;
      max-width: 900px;
      margin: $SPACING_L auto 0;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 150px);
      gap: $SPACING_M;
      @media (min-width: $SCREEN_SIZE_S) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 140px);
      }
      &-item {
        list-style-type: none;
        height: 100%;
        width: 100%;
        perspective: 1000px;
        cursor: pointer;
        border-radius: $BORDER_M;

        &--active &-card {
          transform: rotateX(180deg);
        }
        &-card {
          position: relative;
          height: 100%;
          width: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d;

          & > * {
            position: absolute;
            height: 100%;
            width: calc(100% - $SPACING_M * 2);
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
          }
          &-front,
          &-back {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 $SPACING_M;
            background-color: $LIGHT_GREY;
            gap: $SPACING_M;
            border-radius: $BORDER_M;
            text-align: start;
            &-icon {
              font-size: $TEXT_XL;
            }
          }
          &-back {
            transform: rotateX(180deg);
            background-color: $BLACK;
            color: $WHITE;
            &-icon {
              color: $WHITE;
              font-size: $TEXT_XXL;
            }
            &-anwser ul {
              list-style-type: decimal;
              list-style-position: inside;
            }
          }
        }
      }
    }
  }
  &-volgende {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-button {
      margin-top: $SPACING_M;
    }
  }

  &-animaties {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $LIGHT_GREY;
    z-index: 2;
    color: $BLACK;
    padding-bottom: $SPACING_M;
    height: calc(100vh - $SPACING_M);
    transition: 0.8s;
    overflow: hidden;

    &-content {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: $SPACING_L $SPACING_L $SPACING_L $SPACING_M;
      margin-top: 60px;
      height: 100%;
      @media (min-width: $SCREEN_SIZE_S) {
        margin-top: 61px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      margin: 0 $SPACING_XXL;
      min-width: 350px;
      @media (min-width: $SCREEN_SIZE_S) {
        min-width: 500px;
      }
      &:first-of-type {
        margin: 0 $SPACING_XXL 0 0;
        @media (min-width: $SCREEN_SIZE_S) {
          justify-content: center;
        }
      }
      &:last-of-type {
        margin: 0 0 0 $SPACING_XXL;
        @media (min-width: $SCREEN_SIZE_S) {
          justify-content: center;
        }
      }

      & img {
        width: 350px;
        max-height: 100%;
        @media (min-width: $SCREEN_SIZE_S) {
          width: 500px;
          max-height: 100%;
        }
      }

      &--bottom {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        margin: auto $SPACING_XXL 0 $SPACING_XXL;
        min-width: 100%;
        @media (min-width: $SCREEN_SIZE_S) {
          min-width: 500px;
          max-width: 100%;
        }
        &:first-of-type {
          margin: 0 $SPACING_XXL 0 0;
        }

        & img {
          width: 350px;
          max-height: 100%;
          @media (min-width: $SCREEN_SIZE_S) {
            width: 500px;
            max-height: 100%;
          }
        }
      }
    }
    &-title {
      font-size: $TEXT_6XL;
      margin-bottom: $SPACING_L;
      color: $LIGHTER_BLACK;

      &--colored {
        display: block;
        background-image: linear-gradient(0deg, $BLUE 25%, $LIGHT_BLUE 75%);
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        will-change: transform;
      }
    }
    &-text {
      font-size: $TEXT_XL;
      font-weight: $REGULAR;

      &--scroll {
        display: flex;
        align-items: center;
        gap: $SPACING_XS;

        @media (min-width: $SCREEN_SIZE_S) {
          display: none;
        }

        & span {
          font-size: $TEXT_XL;
          display: flex;
          align-items: center;
        }
      }

      &-container {
        margin: $SPACING_XXL 0;
        background-color: $GREY;
        padding: $SPACING_S;
        border-radius: $BORDER_M;
        & h2 {
          font-size: $TEXT_XXL;
          margin-bottom: $SPACING_XS;
          color: $BLUE;
        }
      }
    }
    &-foto {
      max-height: 200px;
    }

    &-footer {
      position: sticky;
      display: flex;
      gap: $SPACING_S;
      align-items: center;
      width: calc(100% - $SPACING_S * 2);
      padding: 0 $SPACING_S;

      &-progression {
        flex-grow: 1;
        height: 3px;
        border-radius: $BORDER_M;
        background-color: $DARK_GREY;
        max-width: 300px;
        &-bar {
          height: 100%;
          background-color: $BLACK;
          border-radius: $BORDER_M;
        }
      }
    }
  }

  &-project {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: visibility 0.2s, opacity 0.2s linear;
    @media (min-width: $SCREEN_SIZE_S) {
      padding: $SPACING_XXL $SPACING_L;
      height: calc(100vh - $SPACING_XXL * 2);
      width: calc(100vw - $SPACING_L * 2);
    }

    &--active {
      opacity: 1;
      visibility: visible;
    }

    &-container {
      position: relative;
      width: calc(100% - $SPACING_M * 2);
      max-width: $SCREEN_SIZE_M;
      margin: 0 auto;
      background-color: $LIGHTER_BLACK;
      color: $WHITE;
      display: flex;
      flex-direction: column;
      align-items: center;
      transform: translateY(5rem);
      transition-delay: 100ms;
      opacity: 0;
      visibility: hidden;
      transition: 0.4s;
      padding: $SPACING_XXL $SPACING_M;
      @media (min-width: $SCREEN_SIZE_S) {
        border-radius: $BORDER_M;
        width: calc(100% - $SPACING_XXL * 2);
        padding: $SPACING_XXL $SPACING_M;
      }

      &--active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    &-type {
      background-color: $GREY;
      color: $BLACK;
      padding: $SPACING_XS;
      border-radius: $BORDER_M;
      font-size: $TEXT_M;
      font-weight: $REGULAR;
    }

    &-title {
      text-align: center;

      &-block {
        display: flex;
        align-items: center;
        gap: $SPACING_XS;

        & a {
          color: $WHITE;
        }
      }
    }

    &-border {
      width: 100%;
      height: 1px;
      background-color: $DARK_GREY;
      margin: $SPACING_XL auto;
      opacity: 0.3;
    }

    &-close {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: $SPACING_L $SPACING_M;
      padding: $SPACING_XS;
      font-size: $TEXT_XXL;
      background-color: $DARK_GREY;
      color: $BLACK;
      top: 0;
      left: 0;
      border-radius: $BORDER_XL;
      cursor: pointer;
    }

    &-image {
      max-height: 400px;
      max-width: 100%;
      margin: 0 auto;

      &--spacing {
        max-height: 300px;
        margin: 0 auto;
        padding: $SPACING_XL 0;
        @media (min-width: $SCREEN_SIZE_S) {
          max-height: 400px;
        }
      }
    }

    &-text {
      &-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: $SPACING_XL;
        width: 100%;

        @media (min-width: $SCREEN_SIZE_S) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &-tools {
      &-title {
        margin-bottom: $SPACING_S;
      }
      &-list {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $SPACING_S;

        &-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: $SPACING_XXS;
          font-size: $TEXT_4XL;
          color: $BLUE;
        }
      }
    }

    &-photos {
      width: 100%;
      margin-bottom: $SPACING_L;
      @media (min-width: $SCREEN_SIZE_S) {
        margin-bottom: 0;
      }

      &-carousel {
        display: flex;
        gap: $SPACING_XL;
        width: 100%;
        overflow-x: scroll;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        &-item {
          width: 100%;
          max-height: 400px;
          scroll-snap-align: center;
        }
      }

      &-bar {
        position: relative;
        width: 100%;
        max-width: 600px;
        height: 5px;
        background-color: $DARK_GREY;
        border-radius: $BORDER_XL;
        transition: 0.2s;
        margin: $SPACING_XL auto 0;

        &--progress {
          position: absolute;
          left: 0;
          top: 0;
          width: calc(100% / 11 * 3);
          height: 100%;
          background-color: $WHITE;
          border-radius: $BORDER_XL;
          cursor: pointer;
        }
      }
    }

    &-button {
      margin-top: $SPACING_L;
    }
  }
}

.noscroll {
  overflow: hidden;
}
[aria-hidden="true"] {
  transform: translateX(100%);
}
[aria-hidden="false"] {
  transform: translateX(0);
}

.machine {
  fill: $GREEN;
}
.small-shadow,
.medium-shadow,
.large-shadow {
  fill: rgba(0, 0, 0, 0.05);
}
.small {
  -webkit-animation: counter-rotation 4s infinite linear;
  -moz-animation: counter-rotation 4s infinite linear;
  -o-animation: counter-rotation 4s infinite linear;
  animation: counter-rotation 4s infinite linear;
  -webkit-transform-origin: 100.136px 225.345px;
  -ms-transform-origin: 100.136px 225.345px;
  transform-origin: 100.136px 225.345px;
}

.small-shadow {
  -webkit-animation: counter-rotation 4s infinite linear;
  -moz-animation: counter-rotation 4s infinite linear;
  -o-animation: counter-rotation 4s infinite linear;
  animation: counter-rotation 4s infinite linear;
  -webkit-transform-origin: 110.136px 235.345px;
  -ms-transform-origin: 110.136px 235.345px;
  transform-origin: 110.136px 235.345px;
}

.medium {
  -webkit-animation: rotation 6s infinite linear;
  -moz-animation: rotation 6s infinite linear;
  -o-animation: rotation 6s infinite linear;
  animation: rotation 6s infinite linear;
  -webkit-transform-origin: 254.675px 379.447px;
  -ms-transform-origin: 254.675px 379.447px;
  transform-origin: 254.675px 379.447px;
}

.medium-shadow {
  -webkit-animation: rotation 6s infinite linear;
  -moz-animation: rotation 6s infinite linear;
  -o-animation: rotation 6s infinite linear;
  animation: rotation 6s infinite linear;
  -webkit-transform-origin: 264.675px 389.447px;
  -ms-transform-origin: 264.675px 389.447px;
  transform-origin: 264.675px 389.447px;
}

.large {
  -webkit-animation: counter-rotation 8s infinite linear;
  -moz-animation: counter-rotation 8s infinite linear;
  -o-animation: counter-rotation 8s infinite linear;
  animation: counter-rotation 8s infinite linear;
  -webkit-transform-origin: 461.37px 173.694px;
  -ms-transform-origin: 461.37px 173.694px;
  transform-origin: 461.37px 173.694px;
}

.large-shadow {
  -webkit-animation: counter-rotation 8s infinite linear;
  -moz-animation: counter-rotation 8s infinite linear;
  -o-animation: counter-rotation 8s infinite linear;
  animation: counter-rotation 8s infinite linear;
  -webkit-transform-origin: 471.37px 183.694px;
  -ms-transform-origin: 471.37px 183.694px;
  transform-origin: 471.37px 183.694px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes counter-rotation {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes counter-rotation {
  from {
    -moz-transform: rotate(359deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes counter-rotation {
  from {
    -o-transform: rotate(359deg);
  }
  to {
    -o-transform: rotate(0deg);
  }
}
@keyframes counter-rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
