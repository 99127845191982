@import "../Styles.scss";

.contact {
    max-width: $SCREEN_SIZE_M;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 $SPACING_M;
    @media (min-width: $SCREEN_SIZE_S) {
        padding: 0 $SPACING_L;
        flex-direction: row;
    }

    &-title {
        font-size: $TEXT_3XL;
        font-weight: $MEDIUM;
    }
    &-underline {
        &-blue {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: $TEXT_XXS;
                background-color: $LIGHT_BLUE;
                z-index: -1;
            }
        }
    }
    &-offer {
        margin: $SPACING_M 0;
        background-color: $GREY;
        padding: $SPACING_S;
        border-radius: $BORDER_M;

        &-title {
            font-size: $TEXT_XL;
            font-weight: $MEDIUM;
            margin-bottom: $SPACING_XS;
        }
        &-link {
            font-size: $TEXT_M;
            text-decoration: underline;
        }
    }
    &-form {
        flex-basis: 50%;
        & form {
            display: flex;
            flex-direction: column;
            margin-top: $SPACING_M;
        }
        &-container {
            margin: $SPACING_XS 0;
            display: flex;
            flex-direction: column;
        }
        &-labels {
            display: flex;
            justify-content: space-between;
            &--error {
                color: red;
            }
        }
        &-input {
            border: none;
            background-color: $GREY;
            padding: $SPACING_S $SPACING_XS;
            border-radius: $BORDER_M;
            margin-top: $SPACING_XXS;
            font-size: $TEXT_L;

            &:focus {
                outline: none;
            }
            &--file {
                position: relative;
                & label {
                    display: block;
                    border: 2px solid $DARK_BLUE;
                    border-radius: $BORDER_XL;
                    padding: $SPACING_S;
                    cursor: pointer;
                    transition: 0.2s;
                    &:hover {
                        background-color: $DARK_BLUE;
                        color: $WHITE;
                    }
                }
                & input {
                    height: 0.1px;
                    opacity: 0;
                    position: absolute;
                    width: 0.1px;
                }
            }
        }
        &-text-area {
            border: none;
            background-color: $GREY;
            padding: $SPACING_S $SPACING_XS;
            border-radius: $BORDER_M;
            margin-top: $SPACING_XXS;
            font-size: $TEXT_L;
            resize: none;
            font-family: inherit;
            height: 100px;
            &:focus {
                outline: none;
            }
        }
        &-buttons {
            margin: $SPACING_XXS 0;
            @keyframes rotation {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(359deg);
                }
              }
            &-loading {
                animation: rotation 1s infinite linear;
                height: 30px;
                width: 30px;
            }
            &-error {
                margin-top: $SPACING_XS;
                color: red;
                & > a {
                    text-decoration: underline;
                    color: $BLACK;
                    font-size: $TEXT_L;
                }
            }
            &-success {
                margin-top: $SPACING_XS;
                color: $GREEN;
                font-size: $TEXT_L;
                font-weight: $MEDIUM;
            }
        }
        &-selected-file {
            display: flex;
            margin-top: $SPACING_S;
            & > svg {
                font-size: $TEXT_3XL;
                cursor: pointer;
            }
            & p {
                width: 50%;
                word-break: break-all;
            }
        }
    }
    &-border {
        padding: $SPACING_XL 0;
        &-line {
            height: 1px;
            width: 100%;
            background-color: $BLACK;
            @media (min-width: $SCREEN_SIZE_S) {
                width: 1px;
                height: 100%;
            }
        }
        @media (min-width: $SCREEN_SIZE_S) {
            padding: 0 $SPACING_XL;
        }
    }
    &-info {
        flex-basis: 50%;
        font-size: $TEXT_XL;

        &-container {
            margin: $SPACING_L 0;
            @media (min-width: $SCREEN_SIZE_S) {
                display: flex;
                gap: $SPACING_S;
            }
        }
        &-title {
            font-weight: $MEDIUM;
            margin-bottom: $SPACING_XXS;
        }
        &-image {
            max-height: 100px;
        }
        &-text {
            font-size: $TEXT_L;
            & a {
                font-size: $TEXT_L;
                text-decoration: underline;
            }
        }
        &-socials {
            margin: $SPACING_XS 0;
            display: flex;
            gap: $SPACING_XS;
            cursor: pointer;
            & > * {
                font-size: $TEXT_3XL;
                transition: 0.2s;
                &:hover {
                    color: $DARK_BLUE;
                }
            }
        }
    }
}
