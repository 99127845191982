@import "../../Styles.scss";

.button {
    padding: $SPACING_S;
    border-radius: $BORDER_XL;
    color: $WHITE;
    fill: $WHITE;
    stroke: $WHITE;
    font-size: $TEXT_3XL;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    align-self: flex-start;
    border: none;
    & > svg > path {
        stroke: $WHITE;
    }

    &-light-blue {
        background-color: $LIGHT_BLUE;
        &--outline {
            border: 2px solid $LIGHT_BLUE;
            color: $BLACK;
            background-color: $WHITE;
            transition: 0.2s;
            &:hover {
                background-color: $LIGHT_BLUE;
                color: $WHITE;
            }
        }
    }
    &-dark-blue {
        background-color: $DARK_BLUE;
        &--outline {
            background-color: transparent;
            border: 2px solid $DARK_BLUE;
            color: $BLACK;
            transition: 0.2s;
            background-color: $WHITE;
            &:hover {
                background-color: $DARK_BLUE;
                color: $WHITE;
            }
        }
    }
    &-green {
        background-color: $GREEN;
        &--outline {
            background-color: transparent;
            border: 1px solid $GREEN;
            color: $BLACK;
        }
    }
    &-dark-grey {
        background-color: $DARK_GREY;
        &--outline {
            background-color: transparent;
            border: 1px solid $DARK_GREY;
            color: $BLACK;
            background-color: $WHITE;
        }
    }
    &-black {
        background-color: $BLACK;
        color: $BLACK;
        fill: $BLACK;
        stroke: $BLACK;
        &--outline {
            background-color: transparent;
            border: 1px solid $BLACK;
            color: $BLACK;
            fill: $BLACK;
            stroke: $BLACK;
            background-color: $WHITE;
        }
    }
}
