@import "./styles/Styles.scss";

.App {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-content {
    width: 100%;
    min-height: 100vh;

    @media (min-width: $SCREEN_SIZE_M) {
      margin: 0 auto;
    }

    > div:nth-of-type(3) {
      // Item under navbar
      padding-top: 5rem;

      @media(min-width: $SCREEN_SIZE_S) {
        padding-top: 5rem;
      }
      @media(min-width: $SCREEN_SIZE_L) {
        padding-bottom: 3.875rem;
      }
    }
  }
}
