@import "../Styles.scss";

.about {
    padding: 0 $SPACING_M;
    @media (min-width: $SCREEN_SIZE_M) {
        padding: 0 $SPACING_L;
    }
    &-grid {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: $SPACING_L;
        max-width: $SCREEN_SIZE_L;
        margin: 0 auto;
        @media (min-width: $SCREEN_SIZE_S) {
            padding: 0 $SPACING_L;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $SPACING_L;
        }
        @media (min-width: $SCREEN_SIZE_M) {
            padding: 0 $SPACING_L;
            grid-template-columns: repeat(5, 1fr);
            column-gap: $SPACING_L;
        }
    }
    &-grid-item {
        position: relative;
        background-color: $LIGHT_GREY;
        width: 100%;
        border-radius: $BORDER_M;

        &:before {
            content: ' ';
            display: block;
            width: 100%;
            padding-top: 100%
        }
        &-content {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            padding: $SPACING_M;
            display: flex;
            flex-direction: column;
        }

        &--large-width {
            position: relative;
            background-color: $LIGHT_GREY;
            width: 100%;
            border-radius: $BORDER_M;
            box-sizing: border-box;
            padding: $SPACING_M;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:before {
                content: ' ';
                display: block;
                width: 100%;
                padding-top: 70%
            }
            @media (min-width: $SCREEN_SIZE_S) {
                grid-column: span 2;
                &:before {
                    display: none;
                }
            }
            &-content {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                padding: $SPACING_M;
                display: flex;
                flex-direction: column;
                @media (min-width: $SCREEN_SIZE_S) {
                    position: relative;
                    padding: unset;
                }
            }
        }
        &--extra-large-width {
            position: relative;
            background-color: $BLACK;
            width: 100%;
            border-radius: $BORDER_M;
            box-sizing: border-box;
            padding: $SPACING_M;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:before {
                content: ' ';
                display: block;
                width: 100%;
                padding-top: 70%
            }
            h1 {
                font-size: $SPACING_L;
            }
            @media (min-width: $SCREEN_SIZE_S) {
                grid-column: span 3;
                &:before {
                    display: none;
                }
            }
        }
    }

    &-image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: $BORDER_M;
    }

    &-socials {
        margin-top: $SPACING_S;
        &-grid {
            margin-top: $SPACING_XS;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $SPACING_S;
            align-items: center;
            justify-items: center;
            & a {
                font-size: $TEXT_4XL;
                transition: 0.2s;
                &:hover {
                    color: $DARK_BLUE;
                }
            }
        }
    }

    &-carousel {
        width: 100%;
        flex-grow: 1;
        display: flex;
        margin: auto;
        overflow-x: scroll;
        margin: $SPACING_L 0;
        &::-webkit-scrollbar {
            display: none;
        }
        &-item {
            width: calc(100%/3);
            min-width: calc(100%/3);
            height: 100%;
            display: flex;
            gap: $SPACING_XS;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
            scroll-snap-align: center;
            color: $BLUE;
            font-size: $TEXT_5XL;
            @media (min-width: $SCREEN_SIZE_S) {
                width: calc(100%/4);
                min-width: calc(100%/4);
            }
            & p {
                color: $WHITE;
                font-weight: $MEDIUM;
            }
        }
        &-bar {
            position: relative;
            width: 100%;
            height: 5px;
            background-color: $DARK_GREY;
            border-radius: $BORDER_XL;
            opacity: 1;
            transition: 0.2s;

            &--progress {
                position: absolute;
                left: 0;
                top: 0;
                width: calc(100%/11*3);
                height: 100%;
                background-color: $WHITE;
                border-radius: $BORDER_XL;
                cursor: pointer;
            }
        }
    }
    &-languages {
        & h1 {
            margin: 0;
            background: -webkit-linear-gradient($BLUE, $LIGHT_BLUE);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &-logos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: $SPACING_M;
        row-gap: $SPACING_XL;
        padding: $SPACING_M 0;
        margin: auto 0;

        a {
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                max-width: 150px;
                object-fit: contain;
            }
        }
    }

    &-container {
        margin: $SPACING_XXL auto;
        max-width: $SCREEN_SIZE_L;
    }
    &-story {
        display: grid;
        grid-template-columns: 1fr;
        gap: $SPACING_L;
        width: 100%;
        @media (min-width: $SCREEN_SIZE_S) {
            grid-template-columns: repeat(2, 1fr);
        }

        &-text {
            margin-top: $SPACING_M;
            & p {
                margin-top: $SPACING_S;
                font-size: $TEXT_XL;
            }
        }
        & img {
            width: 100%;
            border-radius: $BORDER_M;
        }
    }
}
